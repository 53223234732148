import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useEffect, useRef, useState } from "react";

const TransferGudang = () => {
  // Create a map to store total differences based on No_Invoice or Nomor_Transaksi
  const [DataBO, setDataBO] = useState([]);
  const [DataJurnal, setDataJurnal] = useState([]);
  const [boToJurnalPage, setBoToJurnalPage] = useState(0);
  const [boToJurnalRowsPerPage, setBoToJurnalRowsPerPage] = useState(5);

  const handleChangeBoToJurnalPage = (event, newPage) => {
    setBoToJurnalPage(newPage);
  };

  const handleChangeBoToJurnalRowsPerPage = (event) => {
    setBoToJurnalRowsPerPage(parseInt(event.target.value, 10));
    setBoToJurnalPage(0); // Reset page when changing rows per page
  };

  const totalDifferencesMapJurnalToBO = new Map();
  const totalDifferencesMapBOToJurnal = new Map();
  const tableRef = useRef(null);
  const [salesPage, setsalesPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set your desired initial rows per page

  const handleChangePage = (event, newPage) => {
    setsalesPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setsalesPage(0); // Reset page when changing rows per page
  };

  DataJurnal.forEach((jurnalEntry) => {
    const matchingBOEntry = DataBO.find(
      (boEntry) =>
        boEntry["No_Transaksi_(Jurnal)"] +
          boEntry["Gudang_Asal"] +
          boEntry["Nama_Produk"] ==
        jurnalEntry["No_Transaksi"] +
          jurnalEntry["Gudang"] +
          jurnalEntry["Produk"]
    );
    console.log(
      "jURNAL :" +
        jurnalEntry["No_Transaksi"] +
        jurnalEntry["Gudang"] +
        jurnalEntry["Produk"]
    );
    if (matchingBOEntry) {
      const difference =
        convert_positive(parseFloat(jurnalEntry["Perpindahan_Stok"], 10)) -
        convert_positive(parseFloat(matchingBOEntry["Kuantitas"], 10));

      // Only add to the map if the difference is more than 10
      if (Math.abs(difference) > 0) {
        totalDifferencesMapJurnalToBO.set({
          Nomor_Transaksi: jurnalEntry["No_Transaksi"],
          Produk: jurnalEntry["Produk"],
          Total_Differnce: convert_positive(difference),
        });
      }
    } else {
      // Add entries with no matching No_Invoice to the map
      totalDifferencesMapJurnalToBO.set({
        Nomor_Transaksi: jurnalEntry["No_Transaksi"],
        Produk: jurnalEntry["Produk"],
        Total_Differnce: "Invoice Tidak Ditemukan Di Supply Chain",
      });
    }
  });
  function convert_positive(a) {
    // Check the number is negative
    if (a < 0) {
      // Multiply number with -1
      // to make it positive
      a = a * -1;
    }
    // Return the positive number
    return a;
  }
  // Calculate total differences from DataBO to DataJurnal
  DataBO.forEach((boEntry) => {
    const matchingJurnalEntry = DataJurnal.find(
      (jurnalEntry) =>
        jurnalEntry["No_Transaksi"] +
          jurnalEntry["Gudang"] +
          jurnalEntry["Produk"] ==
        boEntry["No_Transaksi_(Jurnal)"] +
          boEntry["Gudang_Asal"] +
          boEntry["Nama_Produk"]
    );

    console.log(
      "BO :" +
        boEntry["No_Transaksi_(Jurnal)"] +
        boEntry["Gudang_Asal"] +
        boEntry["Nama_Produk"]
    );
    if (matchingJurnalEntry) {
      const difference =
        convert_positive(parseFloat(boEntry["Kuantitas"]), 10) -
        convert_positive(
          parseFloat(matchingJurnalEntry["Perpindahan_Stok"]),
          10
        );

      // Only add to the map if the difference is more than 10
      if (Math.abs(difference) > 0) {
        totalDifferencesMapBOToJurnal.set({
          Nomor_Transaksi: boEntry["No_Transaksi_(Jurnal)"],
          Kode_WO: boEntry["Kode_WO"],
          Pelanggan: boEntry["Pelanggan"],
          Produk: boEntry["Nama_Produk"],
          Total_Differnce: difference,
        });
      }
    } else {
      // Add entries with no matching Nomor_Transaksi to the map
      totalDifferencesMapBOToJurnal.set({
        Nomor_Transaksi: boEntry["No_Transaksi_(Jurnal)"],
        Kode_WO: boEntry["Kode_WO"],
        Pelanggan: boEntry["Pelanggan"],
        Produk: boEntry["Nama_Produk"],
        Total_Differnce: "Nomor Transaksi Tidak Ditemukan Di Jurnal",
      });
    }
  });

  // Convert the maps to arrays for rendering
  const totalDifferencesArrayJurnalToBO = Array.from(
    totalDifferencesMapJurnalToBO,
    ([Nomor]) => ({ Nomor })
  );

  const totalDifferencesArrayBOToJurnal = Array.from(
    totalDifferencesMapBOToJurnal,
    ([Nomor]) => ({ Nomor })
  );
  const [isScrollingUp, setIsScrollingUp] = useState(true);

  const exportToCSV = (data, filename) => {
    // Assuming data is an array of objects with properties you want as headers
    const headers = Object.keys(data[0].Nomor);

    const csvData = [
      headers, // Headers
      ...data.map((row) => headers.map((header) => row.Nomor[header])),
    ];

    const csvContent = csvData
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Export to CSV is not supported in this browser");
    }
  };
  const handleFileUpload = (event, setDataFunction) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          const data = result.data;
          const isTipeTransaksiPresent = data[0] && "Tipe Transaksi" in data[0];

          // Apply filter only if 'Tipe Transaksi' key is present
          const newData = isTipeTransaksiPresent
            ? data
                .filter((row) => row["Tipe Transaksi"] === "Warehouse Transfer")
                .map((row) => {
                  const newObject = {};
                  Object.keys(row).forEach((key) => {
                    // Replace spaces in header keys with underscores
                    const newKey = key.replace(/\s/g, "_");
                    // Remove \t from the values
                    newObject[newKey] = row[key].replace(/\t/g, "");
                  });
                  return newObject;
                })
            : data;

          const filteredData = newData.map((row) => {
            const newObject = {};
            Object.keys(row).forEach((key) => {
              // Replace spaces in header keys with underscores
              const newKey = key.replace(/\s/g, "_");
              // Remove \t from the values
              newObject[newKey] = row[key].replace(/\t/g, "");
            });
            return newObject;
          });
          setDataFunction(filteredData);
          console.log(filteredData);
        },
      });
    }
  };

  return (
    <Box flexDirection={"row"} display={"flex"}>
      <TableContainer
        sx={{ paddingTop: 10, marginRight: 10 }}
        component={Paper}
      >
        <Typography mx={2}>
          Data Transfer Gudang Jurnal to Data Supply Chain Total :{" "}
          {totalDifferencesArrayJurnalToBO.length}
        </Typography>
        <Box mx={2} display={"flex"} justifyContent={"space-between"}>
          <label htmlFor="upload-photo">
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              accept=".csv"
              onChange={(event) => handleFileUpload(event, setDataJurnal)}
            />

            <Button color="success" variant="contained" component="span">
              Import CSV
            </Button>
          </label>
          {totalDifferencesArrayJurnalToBO.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                exportToCSV(
                  totalDifferencesArrayJurnalToBO,
                  "Transfer Gudang - Jurnal.csv"
                )
              }
            >
              Export to CSV
            </Button>
          )}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nomor Transaksi</TableCell>
              <TableCell>Produk</TableCell>
              <TableCell>Total Difference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalDifferencesArrayJurnalToBO
              .slice(salesPage * rowsPerPage, (salesPage + 1) * rowsPerPage)
              .map((row) => (
                <TableRow key={row.Nomor.Nomor_Transaksi}>
                  <TableCell>{row.Nomor.Nomor_Transaksi}</TableCell>
                  <TableCell>{row.Nomor.Produk}</TableCell>
                  <TableCell>{row.Nomor.Total_Differnce}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalDifferencesArrayJurnalToBO.length}
          page={salesPage}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <TableContainer sx={{ paddingTop: 10 }} component={Paper}>
        <Typography mx={2}>
          Data Transfer Gudang Supply Chain to Data Jurnal Total :{" "}
          {totalDifferencesArrayBOToJurnal.length}
        </Typography>
        <Box mx={2} display={"flex"} justifyContent={"space-between"}>
          <label htmlFor="upload-photos">
            <input
              style={{ display: "none" }}
              id="upload-photos"
              name="upload-photos"
              type="file"
              accept=".csv"
              onChange={(event) => handleFileUpload(event, setDataBO)}
            />

            <Button color="success" variant="contained" component="span">
              Import CSV
            </Button>
          </label>
          {totalDifferencesArrayBOToJurnal.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                exportToCSV(
                  totalDifferencesArrayBOToJurnal,
                  "Transfer Gudang - Supply Chain.csv"
                )
              }
            >
              Export to CSV
            </Button>
          )}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nomor Transaksi</TableCell>
              <TableCell>Kode WO</TableCell>
              <TableCell>Produk</TableCell>
              <TableCell>Pelanggan</TableCell>
              <TableCell>Total Difference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalDifferencesArrayBOToJurnal
              .slice(
                boToJurnalPage * boToJurnalRowsPerPage,
                (boToJurnalPage + 1) * boToJurnalRowsPerPage
              )
              .map((row) => (
                <TableRow key={row.Nomor.Nomor_Transaksi}>
                  <TableCell>{row.Nomor.Nomor_Transaksi}</TableCell>
                  <TableCell>{row.Nomor.Kode_WO}</TableCell>
                  <TableCell>{row.Nomor.Produk}</TableCell>
                  <TableCell>{row.Nomor.Pelanggan}</TableCell>
                  <TableCell>{row.Nomor.Total_Differnce}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalDifferencesArrayBOToJurnal.length}
          page={boToJurnalPage}
          onPageChange={handleChangeBoToJurnalPage}
          rowsPerPage={boToJurnalRowsPerPage}
          onRowsPerPageChange={handleChangeBoToJurnalRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default TransferGudang;
