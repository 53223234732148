import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import React, { useState } from "react";
import Payment from "./component/screen/Payment";
import Sales from "./component/screen/Sales";
import SupplyChain from "./component/screen/SupplyChain";
import Konversi from "./component/screen/Konversi";
import TransferGudang from "./component/screen/Transfer Gudang";
import Product from "./component/screen/Product";
import PengeluaranLain from "./component/screen/PengeluaranLain";
import Contact from "./component/screen/Contact";
import LabelFormat from "./component/screen/LabelFormat";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Sample from "./component/screen/sample";
import Data from "../package.json";
const App = () => {
  // Create a map to store total differences based on No_Invoice or Nomor_Transaksi
  const [page, setpage] = useState("Sales");
  // console.log = console.warn = console.error = () => {};
  console.warn = console.error = () => {};
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box>
        <AppBar>
          <Toolbar>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              {page}
            </Typography>
            <Button
              onClick={() => setpage("Sales")}
              color="inherit"
              variant={page === "Sales" ? "outlined" : "text"}
            >
              Sales
            </Button>
            <Button
              onClick={() => setpage("Payment")}
              color="inherit"
              variant={page === "Payment" ? "outlined" : "text"}
            >
              Payment
            </Button>
            <Button
              onClick={() => setpage("Delivery")}
              color="inherit"
              variant={page === "Delivery" ? "outlined" : "text"}
            >
              Delivery
            </Button>
            <Button
              onClick={() => setpage("Konversi")}
              color="inherit"
              variant={page === "Konversi" ? "outlined" : "text"}
            >
              Konversi
            </Button>
            <Button
              onClick={() => setpage("Transfer Gudang")}
              color="inherit"
              variant={page === "Transfer Gudang" ? "outlined" : "text"}
            >
              Transfer Gudang
            </Button>
            <Button
              onClick={() => setpage("Product")}
              color="inherit"
              variant={page === "Product" ? "outlined" : "text"}
            >
              Product
            </Button>
            <Button
              onClick={() => setpage("Pengeluaran Lain")}
              color="inherit"
              variant={page === "Pengeluaran Lain" ? "outlined" : "text"}
            >
              Pengeluaran Lain
            </Button>
            <Button
              onClick={() => setpage("Contact")}
              color="inherit"
              variant={page === "Contact" ? "outlined" : "text"}
            >
              Contact
            </Button>
            <Button
              onClick={() => setpage("Label Format")}
              color="inherit"
              variant={page === "Label Format" ? "outlined" : "text"}
            >
              Label Format
            </Button>
            {/* <Button
              onClick={() => setpage("Sample")}
              color="inherit"
              variant={page === "Sample" ? "outlined" : "text"}
            >
              Sample
            </Button> */}
          </Toolbar>
        </AppBar>
        <Box></Box>
        {page === "Sales" ? (
          <Sales />
        ) : page === "Delivery" ? (
          <SupplyChain />
        ) : page === "Konversi" ? (
          <Konversi />
        ) : page === "Transfer Gudang" ? (
          <TransferGudang />
        ) : page === "Product" ? (
          <Product />
        ) : page === "Pengeluaran Lain" ? (
          <PengeluaranLain />
        ) : page === "Contact" ? (
          <Contact />
        ) : page === "Payment" ? (
          <Payment />
        ) : page === "Sample" ? (
          <Sample />
        ) : (
          <LabelFormat />
        )}
      </Box>
      <Box position={"fixed"} bottom={0}>
        <Typography>{Data.version}</Typography>
      </Box>
    </LocalizationProvider>
  );
};

export default App;
