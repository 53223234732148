import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState, useRef } from "react";

import Papa from "papaparse";
const Payment = () => {
  const [reverseComparisonResults, setReverseComparisonResults] = useState([]);
  const [DataPaymentBO, setDataPaymentBo] = useState([]);
  const [DataPaymentJurnal, setDataPaymentJurnal] = useState([]);
  const totalDifferencesMapBOToJurnalPayment = new Map();
  const totalDifferencesMapJurnalToBOPayment = new Map();
  const [comparisonResults, setComparisonResults] = useState([]);
  const [pageJurnal, setPageJurnal] = useState(0);
  const [rowsPerPageJurnal, setRowsPerPageJurnal] = useState(5);

  const [boToJurnalPage, setBoToJurnalPage] = useState(0);
  const [boToJurnalRowsPerPage, setBoToJurnalRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPageJurnal(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPageJurnal(parseInt(event.target.value, 10));
    setPageJurnal(0); // Reset page when changing rows per page
  };
  const handleChangeBoToJurnalPage = (event, newPage) => {
    setBoToJurnalPage(newPage);
  };

  const handleChangeBoToJurnalRowsPerPage = (event) => {
    setBoToJurnalRowsPerPage(parseInt(event.target.value, 10));
    setBoToJurnalPage(0); // Reset page when changing rows per page
  };

  // Calculate total differences from DataPaymentBO to DataPaymentJurnal
  DataPaymentBO.forEach((boEntry) => {
    const matchingJurnalEntry = DataPaymentJurnal.find(
      (jurnalEntry) => jurnalEntry.Nomor_Faktur === boEntry.No_Invoice
    );

    if (matchingJurnalEntry) {
      const difference =
        parseFloat((boEntry.Jumlah_Bayar || "0").replace(/,/g, ""), 10) -
        parseFloat(
          (matchingJurnalEntry.Total_Pembayaran || "0").replace(/,/g, ""),
          10
        );

      if (Math.abs(difference) > 10) {
        totalDifferencesMapBOToJurnalPayment.set({
          Nomor_Invoice: boEntry.No_Invoice,
          Nomor_Transaksi: boEntry.No_Transaksi,
          Customer: boEntry.Nama_Golden_Mom,
          Total_Difference: difference,
        });
      }
    } else {
      totalDifferencesMapBOToJurnalPayment.set({
        Nomor_Invoice: boEntry.No_Invoice,
        Nomor_Transaksi: boEntry.No_Transaksi,
        Customer: boEntry.Nama_Golden_Mom,
        Total_Difference: "Nomor Faktur Tidak Ditemukan Di Jurnal",
      });
    }
  });

  // Calculate total differences from DataPaymentJurnal to DataPaymentBO
  DataPaymentJurnal.forEach((jurnalEntry) => {
    const matchingBOEntry = DataPaymentBO.find(
      (boEntry) => boEntry.No_Invoice === jurnalEntry.Nomor_Faktur
    );

    if (matchingBOEntry) {
      const difference =
        parseFloat(
          (jurnalEntry.Total_Pembayaran || "0").replace(/,/g, ""),
          10
        ) -
        parseFloat((matchingBOEntry.Jumlah_Bayar || "0").replace(/,/g, ""), 10);

      if (Math.abs(difference) > 10) {
        totalDifferencesMapJurnalToBOPayment.set({
          Nomor_Invoice: jurnalEntry.Nomor_Faktur,
          Nomor_Transaksi: jurnalEntry.Nomor_Transaksi,
          Customer: jurnalEntry.Nama_Panggilan,
          Total_Difference: difference,
        });
      }
    } else {
      totalDifferencesMapJurnalToBOPayment.set({
        Nomor_Invoice: jurnalEntry.Nomor_Faktur,
        Nomor_Transaksi: jurnalEntry.Nomor_Transaksi,
        Customer: jurnalEntry.Nama_Panggilan,
        Total_Difference: "Invoice Tidak Ditemukan Di Back Office",
      });
    }
  });

  const totalDifferencesArrayBOToJurnalPayment = Array.from(
    totalDifferencesMapBOToJurnalPayment,
    ([value]) => ({ value })
  );

  const totalDifferencesArrayJurnalToBOPayment = Array.from(
    totalDifferencesMapJurnalToBOPayment,
    ([value]) => ({ value })
  );

  const exportToCSV = (data, filename) => {
    console.log(data);
    // Assuming data is an array of objects with properties you want as headers
    const headers = Object.keys(data[0].value);

    const csvData = [
      headers, // Headers
      ...data.map((row) => headers.map((header) => row.value[header])),
    ];

    const csvContent = csvData
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Export to CSV is not supported in this browser");
    }
  };

  const handleFileUpload = (event, setDataFunction) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          const data = result.data;
          const newData = data.map((row) => {
            const newObject = {};
            Object.keys(row).forEach((key) => {
              // Replace spaces in header keys with underscores
              const newKey = key.replace(/\s/g, "_");
              newObject[newKey] =
                key === "Nomor Faktur" ? row[key].replace(/^GM/, "") : row[key];
            });
            return newObject;
          });
          console.log(newData);

          setDataFunction(newData);
        },
      });
    }
  };
  return (
    <Box flexDirection={"row"} display={"flex"}>
      <TableContainer sx={{ paddingTop: 10 }} component={Paper}>
        <Typography mx={2}>
          Data Payment Jurnal to Data Back Office Total :{" "}
          {totalDifferencesArrayJurnalToBOPayment.length}
        </Typography>
        <Box mx={2} display={"flex"} justifyContent={"space-between"}>
          <label htmlFor="upload-photos">
            <input
              style={{ display: "none" }}
              id="upload-photos"
              name="upload-photos"
              type="file"
              accept=".csv"
              onChange={(event) =>
                handleFileUpload(event, setDataPaymentJurnal)
              }
            />

            <Button color="success" variant="contained" component="span">
              Import CSV
            </Button>
          </label>
          {totalDifferencesArrayJurnalToBOPayment.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                exportToCSV(
                  totalDifferencesArrayJurnalToBOPayment,
                  "Payment Jurnal To Back Office.csv"
                )
              }
            >
              Export to CSV
            </Button>
          )}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No Invoice </TableCell>
              <TableCell>Nomor Transaksi</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Total Difference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalDifferencesArrayJurnalToBOPayment
              .slice(
                pageJurnal * rowsPerPageJurnal,
                (pageJurnal + 1) * rowsPerPageJurnal
              )
              .map((row) => (
                <TableRow key={row.value.Nomor_Invoice}>
                  <TableCell>{row.value.Nomor_Invoice}</TableCell>
                  <TableCell>{row.value.Nomor_Transaksi}</TableCell>
                  <TableCell>{row.value.Customer}</TableCell>
                  <TableCell>{row.value.Total_Difference}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalDifferencesArrayJurnalToBOPayment.length}
          page={pageJurnal}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPageJurnal}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <TableContainer sx={{ paddingTop: 10 }} component={Paper}>
        <Typography mx={2}>
          Data Payment Back Office to Data Jurnal Total :{" "}
          {totalDifferencesArrayBOToJurnalPayment.length}
        </Typography>
        <Box mx={2} display={"flex"} justifyContent={"space-between"}>
          <label htmlFor="upload-photo">
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              accept=".csv"
              onChange={(event) => handleFileUpload(event, setDataPaymentBo)}
            />

            <Button color="success" variant="contained" component="span">
              Import CSV
            </Button>
          </label>
          {totalDifferencesArrayBOToJurnalPayment.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                exportToCSV(
                  totalDifferencesArrayBOToJurnalPayment,
                  "Payment back Office To Jurnal.csv"
                )
              }
            >
              Export to CSV
            </Button>
          )}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No Invoice </TableCell>
              <TableCell>Nomor Transaksi</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Total Difference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalDifferencesArrayBOToJurnalPayment
              .slice(
                boToJurnalPage * boToJurnalRowsPerPage,
                (boToJurnalPage + 1) * boToJurnalRowsPerPage
              )
              .map((row) => (
                <TableRow key={row.value.Nomor_Invoice}>
                  <TableCell>{row.value.Nomor_Invoice}</TableCell>
                  <TableCell>{row.value.Nomor_Transaksi}</TableCell>
                  <TableCell>{row.value.Customer}</TableCell>
                  <TableCell>{row.value.Total_Difference}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalDifferencesArrayBOToJurnalPayment.length}
          page={boToJurnalPage}
          onPageChange={handleChangeBoToJurnalPage}
          rowsPerPage={boToJurnalRowsPerPage}
          onRowsPerPageChange={handleChangeBoToJurnalRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default Payment;
