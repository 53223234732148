import { Typography } from "@mui/material";
import React from "react";

const Sample = () => {
  const ww = "210";
  const wh = "148";
  return (
    <div
      style={{
        marginTop: 100,
      }}
    >
      <div>
        <div style={{ position: "absolute" }}>
          {/* <img
            width={ww * 4}
            height={wh * 4}
            src={require("../../assets/images/expired.png")}
          /> */}
        </div>
        <table style={{ position: "absolute" }}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => {
            return (
              <tr key={index}>
                <td
                  style={{
                    paddingRight: ww * 0.05,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>

                <td
                  style={{
                    paddingRight: ww * 0.03,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>
                <td width={ww * 0.1} />
                <td
                  style={{
                    paddingRight: ww * 0.1,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>

                <td
                  style={{
                    paddingRight: ww * 0.03,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>
                <td width={ww * 0.1} />
                <td
                  style={{
                    paddingRight: ww * 0.03,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>

                <td
                  style={{
                    paddingRight: ww * 0.03,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default Sample;
