import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import Papa from "papaparse";
import React, { useEffect, useRef, useState } from "react";

const Sales = () => {
  // Create a map to store total differences based on No_Invoice or Nomor_Transaksi
  const [DataBO, setDataBO] = useState([]);
  const [DataJurnal, setDataJurnal] = useState([]);
  const [boToJurnalPage, setBoToJurnalPage] = useState(0);
  const [boToJurnalRowsPerPage, setBoToJurnalRowsPerPage] = useState(5);

  const handleChangeBoToJurnalPage = (event, newPage) => {
    setBoToJurnalPage(newPage);
  };

  const handleChangeBoToJurnalRowsPerPage = (event) => {
    setBoToJurnalRowsPerPage(parseInt(event.target.Total_Difference, 10));
    setBoToJurnalPage(0); // Reset page when changing rows per page
  };

  const totalDifferencesMapJurnalToBO = new Map();
  const totalDifferencesMapBOToJurnal = new Map();
  const tableRef = useRef(null);
  const [salesPage, setsalesPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set your desired initial rows per page

  const handleChangePage = (event, newPage) => {
    setsalesPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.Total_Difference, 10));
    setsalesPage(0); // Reset page when changing rows per page
  };

  DataJurnal.forEach((jurnalEntry) => {
    const matchingBOEntry = DataBO.find(
      (boEntry) => boEntry.No_Invoice == jurnalEntry.Nomor_Transaksi
    );

    if (matchingBOEntry) {
      const difference = (jurnalEntry.Total, 10) - (matchingBOEntry.Total, 10);

      // Only add to the map if the difference is more than 10
      if (Math.abs(difference) > 10) {
        totalDifferencesMapJurnalToBO.set({
          Nomor_Invoice: jurnalEntry.Nomor_Transaksi,
          Customer: jurnalEntry.Nama_Panggilan,
          Total_Difference: difference,
        });
      }
    } else {
      // Add entries with no matching No_Invoice to the map

      totalDifferencesMapJurnalToBO.set({
        Nomor_Invoice: jurnalEntry.Nomor_Transaksi,
        Customer: jurnalEntry.Nama_Panggilan,
        Total_Difference: "Invoice Tidak Ditemukan Di Back Office (1)",
      });
    }
  });

  // Calculate total differences from DataBO to DataJurnal
  DataBO.forEach((boEntry) => {
    console.log(boEntry.Total);
    const matchingJurnalEntry = DataJurnal.find(
      (jurnalEntry) => jurnalEntry.Nomor_Transaksi == boEntry.No_Invoice
    );

    if (matchingJurnalEntry) {
      const difference = (boEntry.Total, 10) - (matchingJurnalEntry.Total, 10);

      // Only add to the map if the difference is more than 10
      if (Math.abs(difference) > 10) {
        totalDifferencesMapBOToJurnal.set({
          Nomor_Invoice: boEntry["No_Invoice"],
          Customer: boEntry.Golden_Mom,
          Total_Difference: difference,
        });
      }
    } else {
      // Add entries with no matching Nomor_Transaksi to the map
      totalDifferencesMapBOToJurnal.set({
        Nomor_Invoice: boEntry["No_Invoice"],
        Customer: boEntry.Golden_Mom,
        Total_Difference: "Nomor Transaksi Tidak Ditemukan Di Jurnal",
      });
    }
  });

  // Convert the maps to arrays for rendering
  const totalDifferencesArrayJurnalToBO = Array.from(
    totalDifferencesMapJurnalToBO,
    ([Data]) => ({ Data })
  );

  const totalDifferencesArrayBOToJurnal = Array.from(
    totalDifferencesMapBOToJurnal,
    ([Data]) => ({ Data })
  );

  const [isScrollingUp, setIsScrollingUp] = useState(true);

  useEffect(() => {
    let prevScrollPos = window.scrollY;

    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      setIsScrollingUp(currentScrollPos < prevScrollPos);
      prevScrollPos = currentScrollPos;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const exportToCSV = (data, filename) => {
    // Assuming data is an array of objects with properties you want as headers
    const headers = Object.keys(data[0].Data);

    const csvData = [
      headers, // Headers
      ...data.map((row) => headers.map((header) => row.Data[header])),
    ];

    const csvContent = csvData
      .map((row) => row.map((cell) => `"${cell}"`).join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Export to CSV is not supported in this browser");
    }
  };
  const handleFileUpload = (event, setDataFunction) => {
    const file = event.target.files[0];
    if (file) {
      Papa.parse(file, {
        header: true,
        complete: (result) => {
          const data = result.data;
          const newData = data.map((row) => {
            const newObject = {};
            Object.keys(row).forEach((key) => {
              // Replace spaces in header keys with underscores
              const newKey = key.replace(/\s/g, "_");
              newObject[newKey] =
                key == "Nomor Transaksi"
                  ? row[key].replace(/^GM/, "")
                  : key == "Total"
                  ? row[key].replace(/,/g, "") // Remove commas from "Total" key
                  : row[key];
            });
            return newObject;
          });
          console.log(newData);
          setDataFunction(newData);
        },
      });
    }
  };

  return (
    <Box flexDirection={"row"} display={"flex"}>
      <TableContainer
        sx={{ paddingTop: 10, marginRight: 10 }}
        component={Paper}
      >
        <Typography mx={2}>
          Data Sales Jurnal to Data Back Office Total :{" "}
          {totalDifferencesArrayJurnalToBO.length}
        </Typography>
        <Box mx={2} display={"flex"} justifyContent={"space-between"}>
          <label htmlFor="upload-photo">
            <input
              style={{ display: "none" }}
              id="upload-photo"
              name="upload-photo"
              type="file"
              accept=".csv"
              onChange={(event) => handleFileUpload(event, setDataJurnal)}
            />

            <Button color="success" variant="contained" component="span">
              Import CSV
            </Button>
          </label>
          {totalDifferencesArrayJurnalToBO.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                exportToCSV(
                  totalDifferencesArrayJurnalToBO,
                  "Sales Jurnal To Back Office.csv"
                )
              }
            >
              Export to CSV
            </Button>
          )}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No Invoice / Nomor Transaksi</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Total Difference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalDifferencesArrayJurnalToBO
              .slice(salesPage * rowsPerPage, (salesPage + 1) * rowsPerPage)
              .map((row) => (
                <TableRow key={row.Data.Nomor_Invoice}>
                  <TableCell>{row.Data.Nomor_Invoice}</TableCell>
                  <TableCell>{row.Data.Customer}</TableCell>
                  <TableCell>{row.Data.Total_Difference}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalDifferencesArrayJurnalToBO.length}
          page={salesPage}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <TableContainer sx={{ paddingTop: 10 }} component={Paper}>
        <Typography mx={2}>
          Data Sales Back Office to Data Jurnal Total :{" "}
          {totalDifferencesArrayBOToJurnal.length}
        </Typography>
        <Box mx={2} display={"flex"} justifyContent={"space-between"}>
          <label htmlFor="upload-photos">
            <input
              style={{ display: "none" }}
              id="upload-photos"
              name="upload-photos"
              type="file"
              accept=".csv"
              onChange={(event) => handleFileUpload(event, setDataBO)}
            />

            <Button color="success" variant="contained" component="span">
              Import CSV
            </Button>
          </label>
          {totalDifferencesArrayBOToJurnal.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                exportToCSV(
                  totalDifferencesArrayBOToJurnal,
                  "Sales Back Office To Jurnal.csv"
                )
              }
            >
              Export to CSV
            </Button>
          )}
        </Box>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>No Invoice / Nomor Transaksi</TableCell>
              <TableCell>Customer</TableCell>
              <TableCell>Total Difference</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {totalDifferencesArrayBOToJurnal
              .slice(
                boToJurnalPage * boToJurnalRowsPerPage,
                (boToJurnalPage + 1) * boToJurnalRowsPerPage
              )
              .map((row) => (
                <TableRow key={row.Data.Nomor_Invoice}>
                  <TableCell>{row.Data.Nomor_Invoice}</TableCell>
                  <TableCell>{row.Data.Customer}</TableCell>
                  <TableCell>{row.Data.Total_Difference}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalDifferencesArrayBOToJurnal.length}
          page={boToJurnalPage}
          onPageChange={handleChangeBoToJurnalPage}
          rowsPerPage={boToJurnalRowsPerPage}
          onRowsPerPageChange={handleChangeBoToJurnalRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default Sales;
