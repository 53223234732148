import {
  Autocomplete,
  Box,
  Button,
  ButtonBase,
  Card,
  CircularProgress,
  Menu,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Papa from "papaparse";

import axios from "axios";
import ReactToPrint from "react-to-print";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AddIcCallOutlined } from "@mui/icons-material";
import ModalProduct from "./ModalProduct";
import ModalMom from "./ModalMom";
const top100Films = [
  { label: "The Shawshank Redemption", year: 1994 },
  { label: "The Godfather", year: 1972 },
];

const LabelFormat = () => {
  const [selectedRow, setselectedRow] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const SayurBoxProduct = [
    {
      value: 1,
      label: "GM TELUR ASIN MATANG ISI 4",
    },
    {
      value: 2,
      label: "GM TELUR BEBEK TAWAR ISI 4",
    },
    {
      value: 3,
      label: "GM TELUR ASIN MATANG MERAH UDANG ISI 4",
    },
    {
      value: 4,
      label: "GQE TELUR OMEGA 3 ISI 10",
    },
    {
      value: 5,
      label: "GQE TELUR NEGERI KUNING ISI 10",
    },
    {
      value: 6,
      label: "GQE TELUR NEGERI KUNING SUPER ISI 15",
    },
  ];
  let componentRef = useRef();
  const momUrl =
    "https://ws.goldenmom-service.com/index.php/v4/public/master/GoldenMom";
  const dev =
    "https://supply-chain.goldenmom-service.com/api/v1/public/product";
  const subs =
    "https://supply-chain.goldenmom-service.com/api/v1/public/subcategory";
  const devImage =
    "https://phplaravel-634370-4461456.cloudwaysapps.com/uploads";
  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczovL3BocGxhcmF2ZWwtNjM0MzcwLTQ0NjE0NTYuY2xvdWR3YXlzYXBwcy5jb20vdjEvYXV0aC9wYW5lbC9sb2dpbiIsImlhdCI6MTcxNTA2MjE2MSwiZXhwIjoxNzE1MTQ4NTYxLCJuYmYiOjE3MTUwNjIxNjEsImp0aSI6InVDV09pcUZISFBBbktJUHkiLCJzdWIiOjEsInBydiI6Ijc5YzU4OTZkMTk1NGQyODBlZDM2ZmViODMwNWRkZTg3ZTliYTliMTUifQ.BuPEON02BzI5jygtS0r0o44zr-su1HwmL5ggOtV67rk";
  const BASEURL = dev;
  const BASEURLIMAGE = devImage;
  const apiKey = "gomoxgmi2024";
  const [search, setsearch] = useState("");
  const filter = ["Expired", "Sayur Box", "Sayur Buah", "Alamat"];
  const [dataProduct, setdataProduct] = useState([]);
  const [activeFilter, setActiveFilter] = useState("Expired");
  const [filterProduct, setfilterProduct] = useState([]);
  const [masterMom, setmasterMom] = useState([]);
  const [submit, setsubmit] = useState(false);
  const [errorDouble, seterrorDouble] = useState(false);
  const [errorMomDouble, seterrorMomDouble] = useState(false);
  const [searchValue, setsearchValue] = useState("");
  const [startDate, setstartDate] = useState(null);
  const [isOpen, setisOpen] = useState(false);
  const [activeModal, setactiveModal] = useState(null);
  const [deletedeRow, setdeletedeRow] = useState(null);
  const [dataAlamat, setdataAlamat] = useState([]);
  const dataFilterExpired = ["Produksi", "IDM BKL"];
  const [filterExpired, setfilterExpired] = useState("Produksi");
  const [dataLabel, setDataLabel] = useState([
    {
      id: Math.random(),
      product: null,
      mom: null,
      qty: "",
      expired: null,
      production: null,
    },
  ]);
  const fetchDataProduct = async () => {
    let allData = [];
    let currentPage = 1;
    let url = dev;
    if (activeFilter === "Expired") {
      url = subs;
    }
    axios
      .get(`${url}?search=${search}`)
      .then((res) => {
        const allData = res.data.data.data.filter((item) => {
          return item.name !== "KEBUTUHAN POKOK & DAPUR - BAWANG GORENG";
        });
        const formattedData = allData.map((item, index) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        console.log(allData);
        setfilterProduct(formattedData);
        setdataProduct(formattedData);
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  const fetchDataMom = async () => {
    // let allData = [];
    // let currentPage = 1;
    // let url = dev;

    axios
      .get(`${momUrl}?keyword=${search}`)
      .then((res) => {
        const allData = res.data.data;
        const formattedData = allData.map((item, index) => {
          return {
            value: item.KdGoldenMom,
            label: item.NamaGoldenMom,
            address: item.AlamatKirim,
            area: item.NamaArea,
            areaKirim: item.NamaAreaPengiriman,
          };
        });
        setmasterMom(formattedData);

        // setfilterProduct(formattedData);
        // setdataProduct(formattedData);
      })
      .catch((err) => {
        // console.error(err);
      });
  };
  const handleAddLine = () => {
    const newId = dataLabel.length + Math.random();
    setDataLabel([
      ...dataLabel,
      {
        id: newId,
        product: null,
        mom: null,
        qty: "",
        expired: null,
        production: null,
      },
    ]);
  };
  useEffect(() => {
    if (activeFilter === "Alamat") {
      fetchDataMom();
    } else {
      fetchDataProduct();
    }
  }, [activeFilter, search]);
  // useEffect(() => {
  //   if (componentRef) {
  //     setisLoading(false); // Set isLoading to false when component is rendered
  //   }
  // }, [componentRef]);
  const handleDeleteLine = (id) => {
    setDataLabel(dataLabel.filter((item) => item.id !== id));
  };
  // const checkDuplicateProduct = () => {
  //   // Buat objek untuk menyimpan jumlah kemunculan setiap nama produk
  //   const productCounts = {};

  //   // Iterasi melalui selectedProduct
  //   dataLabel.forEach((item) => {
  //     // Jika produk adalah objek dan memiliki properti 'label'
  //     if (item.product && item.product.label) {
  //       // Tambahkan jumlah kemunculan nama produk ke objek productCounts
  //       productCounts[item.product.label] =
  //         (productCounts[item.product.label] || 0) + 1;
  //     }
  //   });

  //   // Cek jika ada nama produk yang muncul lebih dari sekali
  //   for (const productName in productCounts) {
  //     if (productCounts[productName] > 1) {
  //       seterrorDouble(true);
  //       console.log(`Nama produk '${productName}' muncul lebih dari sekali.`);
  //       // Di sini Anda dapat mengambil tindakan yang sesuai jika ditemukan nama produk yang duplikat
  //     } else {
  //       console.log(dataLabel);
  //     }
  //   }
  // };
  const checkDuplicateProduct = () => {
    const hasDuplicateLabel = dataLabel.some((item, index) => {
      if (!item.product?.label || item.product?.label === "") {
        seterrorDouble(true);
      }
      return (
        item.product?.label !== null &&
        dataLabel.findIndex(
          (obj) =>
            obj.product?.label !== null &&
            obj.product?.label === item.product?.label
        ) !== index
      );
    });

    // Output berdasarkan hasil pengecekan
    if (hasDuplicateLabel) {
      seterrorDouble(true);
    } else {
      seterrorDouble(false);
    }
  };
  const checkDuplicateMom = () => {
    // console.log(dataLabel[0].value);
    const hasDuplicateLabel = dataLabel.some((item, index) => {
      if (!item.mom?.label || item.mom?.label === "") {
        seterrorMomDouble(true);
      }
      return (
        item.mom?.label !== null &&
        dataLabel.findIndex(
          (obj) => obj.mom?.value !== null && obj.mom?.value === item.mom?.value
        ) !== index
      );
    });

    // Output berdasarkan hasil pengecekan
    if (hasDuplicateLabel) {
      seterrorMomDouble(true);
    } else {
      seterrorMomDouble(false);
    }
  };
  useEffect(() => {
    checkDuplicateProduct();
    checkDuplicateMom();
    setsubmit(false);
  }, [dataLabel]);

  const handleProductChange = (id, value, event) => {
    if (value === null) {
      setsubmit(false);
    }
    setDataLabel((prevData) =>
      prevData.map((item) =>
        item.id === activeModal.id ? { ...item, product: value } : item
      )
    );
    setsearch("");
  };
  const handleMomChange = (id, value, event) => {
    if (value === null) {
      setsubmit(false);
    }

    setDataLabel((prevData) =>
      prevData.map((item) =>
        item.id === activeModal.id ? { ...item, mom: value } : item
      )
    );
    setsearch("");
  };
  const handleChangeText = (e) => {
    // console.log(e.target.value === "Enter");
    setsearch(e.target.value);
  };
  const handleQtyChange = (id, text) => {
    let value = text;
    if (text < 1) {
      value = 1;
    }
    setDataLabel((prevData) =>
      prevData.map((item) => (item.id === id ? { ...item, qty: value } : item))
    );
  };
  const handleDate = (id, value) => {
    // console.log(e.toLocaleString());
    console.log(dayjs(value).format("DD-MM-YYYY"));
    setDataLabel((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, expired: value } : item
      )
    );
  };
  const handleDateProduction = (id, value) => {
    // console.log(value.toLocaleString());
    setDataLabel((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, production: value } : item
      )
    );
  };
  const fetchDataMomDate = async (value) => {
    let allData = [];
    let currentPage = 1;
    let url = dev;
    setisLoading(true);
    setDataLabel([]);
    const fetchData = async () => {
      let currentPage = 1;
      let allData = [];

      while (true) {
        const res = await axios.get(
          `${momUrl}?transaction_date=${dayjs(value).format(
            "YYYY-MM-DD"
          )}&page=${currentPage}`
        );
        allData = [...allData, ...res.data.data];

        if (res.data.data.length === 0) {
          setisLoading(false);
          break;
        }

        currentPage++;
      }

      const formattedData = allData.map((item, index) => {
        return {
          id: Math.random(), // Membuat ID acak
          mom: {
            value: item.KdGoldenMom,
            label: item.NamaGoldenMom,
            address: item.AlamatKirim,
            area: item.NamaArea,
            areaKirim: item.NamaAreaPengiriman,
            // Masukkan data lain yang ingin Anda masukkan ke dalam mom
          },
          qty: 1,
        };
      });
      const filteredData = formattedData.filter(
        (item) => item.mom.label !== null
      );
      setDataLabel(filteredData);
    };

    fetchData();
  };
  const handleSearchDate = (value) => {
    setmasterMom([]);
    setDataLabel([]);
    setstartDate(value);
    setsearch("");
    fetchDataMomDate(value);
  };
  const changeFilter = (item) => {
    setsubmit(false);
    setsearch("");
    setActiveFilter(item);
    setDataLabel([
      {
        id: Math.random(),
        product: null,
        mom: null,
        qty: "",
        expired: null,
        production: null,
      },
    ]);
  };
  const handleProcess = () => {
    setisLoading(true);

    setTimeout(() => {
      // setisLoading(false);
      setsubmit(true);
      console.log("selesai");
    }, dataLabel.length * 100);
    setTimeout(() => {
      setisLoading(false);
    }, 1000);
  };

  const handleOpenModal = (item) => {
    setactiveModal(item);
    setisOpen(true);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFilterExpired = (item) => {
    setDataLabel([
      {
        id: Math.random(),
        product: null,
        mom: null,
        qty: "",
        expired: null,
        production: null,
      },
    ]);
    setfilterExpired(item);
  };
  return (
    <Card
      display={"flex"}
      sx={{
        boxShadow: 4,
        minHeight: "100vh",
        // padding: 3,
        // maxWidth: "80%",
        mx: "auto",
      }}
    >
      {isLoading && (
        <Stack
          pt={10}
          justifyContent={"center"}
          alignItems={"center"}
          zIndex={10}
          position={"fixed"}
          // top={"50%"}
          // left={"50%"}
          // transform={"translate(-50%, -50%)"}
          bgcolor={"rgba(0,0,0,0.54)"}
          width={"100vw"}
          alignSelf={"center"}
          minHeight={"100vh"}
        >
          <CircularProgress />
        </Stack>
      )}
      <Stack m={5} mt={10} direction={"row"} spacing={5}>
        {filter.map((item, index) => (
          <Button
            key={index}
            onClick={() => changeFilter(item)}
            variant={activeFilter === item ? "contained" : "outlined"}
          >
            {item}
          </Button>
        ))}
        <Stack flex={1} sx={{ alignItems: "flex-end" }}>
          <Button
            // disabled={
            //   activeFilter === "Alamat"
            //     ? filterExpired === "Produksi"
            //       ? errorMomDouble
            //       : errorDouble
            //     : false
            // }
            sx={{ maxWidth: 100 }}
            variant={"contained"}
            onClick={handleAddLine}
          >
            Add Line
          </Button>
        </Stack>
        {/* <Typography>{JSON.stringify(filterProduct)}</Typography> */}
      </Stack>
      {activeFilter === "Expired" && (
        <Stack
          m={5}
          sx={{
            my: 2,
            borderColor: "black",
            bgcolor: "white",
          }}
        >
          <Stack
            borderColor={"gray"}
            key={1}
            sx={{ py: 2 }}
            gap={2}
            flexDirection={"row"}
            // justifyContent={"space-between"}
          >
            {dataFilterExpired.map((item, index) => (
              <Button
                key={index}
                onClick={() => handleFilterExpired(item)}
                variant={filterExpired === item ? "contained" : "outlined"}
              >
                {item}
              </Button>
            ))}
          </Stack>
        </Stack>
      )}

      {activeFilter === "Alamat" && (
        <Stack m={5} direction={"row"} spacing={5}>
          <DatePicker
            onChange={(newValue) => handleSearchDate(newValue)}
            format="DD/MM/YYYY"
            label="Transaction Date"
          />
        </Stack>
      )}
      <Stack
        m={5}
        sx={{
          my: 2,
          borderColor: "black",
          bgcolor: "white",
        }}
      >
        {dataLabel.map((item, index) => (
          <Stack
            borderColor={"gray"}
            key={index}
            sx={{ py: 2 }}
            gap={2}
            flexDirection={"row"}
            // justifyContent={"space-between"}
          >
            <Stack gap={2} flexDirection={"row"}>
              {((activeFilter === "Expired" && filterExpired === "Produksi") ||
                activeFilter === "Sayur Buah" ||
                activeFilter === "Sayur Box") && (
                <Stack>
                  <Button
                    onClick={() => handleOpenModal(item)}
                    sx={{
                      width: "300px",
                      boxShadow: 0,
                      height: "55px",
                    }}
                    variant={item.product !== null ? "contained" : "outlined"}
                    color="primary"
                    // onClick={() => handleRemoveLine(index)}
                  >
                    {/* <AddIcCallOutlined sx={{ mr: 1 }} /> */}
                    {item.product !== null
                      ? item.product.label
                      : "Tambah Product"}
                  </Button>
                  {errorDouble && (
                    <Typography color="red" fontSize={10}>
                      Ada kesamaan produk!
                    </Typography>
                  )}
                </Stack>
              )}
              {/* <Typography>{JSON.stringify(item)}</Typography> */}
              {activeFilter === "Alamat" && (
                <Stack>
                  {/* <Autocomplete
                    // value={{
                    //   value: item.mom !== null ? item.mom.value : "",
                    //   label: item.mom !== null ? item.mom.label : "",
                    // }}
                    defaultValue={{
                      value: item.mom !== null ? item.mom.value : "",
                      label: item.mom !== null ? item.mom.label : "",
                    }}
                    disablePortal
                    id={`combo-box-demo-${item.id}`}
                    sx={{ width: 300 }}
                    options={masterMom}
                    onChange={(event, value) =>
                      handleMomChange(item.id, value, event)
                    }
                    renderInput={(params) => (
                      <TextField
                        value={search}
                        onChange={handleChangeText}
                        {...params}
                        label="Mom"
                      />
                    )}
                  /> */}
                  <Button
                    onClick={() => handleOpenModal(item)}
                    sx={{
                      width: "300px",
                      boxShadow: 0,
                      height: "55px",
                    }}
                    variant={item.mom !== null ? "contained" : "outlined"}
                    color="primary"
                    // onClick={() => handleRemoveLine(index)}
                  >
                    {/* <AddIcCallOutlined sx={{ mr: 1 }} /> */}
                    {item.mom !== null ? item.mom.label : "Tambah Mom"}
                  </Button>
                  {errorMomDouble && (
                    <Typography color="red" fontSize={10}>
                      Ada kesamaan mom!
                    </Typography>
                  )}
                </Stack>
              )}
              {activeFilter === "Alamat" ? (
                <Stack>
                  {/* <Typography>{JSON.stringify(item)}</Typography> */}
                  <TextField
                    onFocus={() => setsubmit(false)}
                    disabled={item.mom === null}
                    value={item.qty}
                    type="number"
                    id={`outlined-basic-${item.id}`}
                    label="Jumlah"
                    variant="outlined"
                    onChange={(e) => handleQtyChange(item.id, e.target.value)}
                  />
                  {item.mom === null && (
                    <Typography color={"red"} fontSize={10}>
                      Mom Belum Dipilih!
                    </Typography>
                  )}
                </Stack>
              ) : filterExpired === "Produksi" ? (
                <Stack>
                  {/* <Typography>{JSON.stringify(item)}</Typography> */}
                  <TextField
                    onFocus={() => setsubmit(false)}
                    disabled={!item.product ? true : false}
                    value={item.qty}
                    type="number"
                    id={`outlined-basic-${item.id}`}
                    label="Jumlah"
                    variant="outlined"
                    onChange={(e) => handleQtyChange(item.id, e.target.value)}
                  />

                  {(item.product === null || item.product === "") && (
                    <Typography color={"red"} fontSize={10}>
                      {activeFilter === "Alamat"
                        ? "Mom Belum Dipilih!"
                        : `Produk Belum Dipilih!`}
                    </Typography>
                  )}
                  {item.product !== null &&
                    dataLabel.some((item) => item.qty < 1) && (
                      <Typography color={"red"} fontSize={10}>
                        Masukan Jumlah
                      </Typography>
                    )}
                </Stack>
              ) : (
                <Stack>
                  {/* <Typography>{JSON.stringify(item)}</Typography> */}
                  <TextField
                    onFocus={() => setsubmit(false)}
                    value={item.qty}
                    type="number"
                    id={`outlined-basic-${item.id}`}
                    label="Jumlah"
                    variant="outlined"
                    onChange={(e) => handleQtyChange(item.id, e.target.value)}
                  />
                </Stack>
              )}
              {activeFilter === "Sayur Box" && (
                <Stack>
                  <DatePicker
                    value={item.production}
                    onChange={(newValue) =>
                      handleDateProduction(item.id, newValue)
                    }
                    format="DD/MM/YYYY"
                    label="Production"
                  />
                  {dataLabel.some((item) => item.production === null) && (
                    <Typography color={"red"} fontSize={10}>
                      Masukan Tanggal
                    </Typography>
                  )}
                </Stack>
              )}
              {activeFilter === "Expired" && filterExpired !== "Produksi" && (
                <Stack>
                  <DatePicker
                    value={item.production}
                    onChange={(newValue) =>
                      handleDateProduction(item.id, newValue)
                    }
                    format="DD/MM/YYYY"
                    label="Production"
                  />
                  {dataLabel.some((item) => item.production === null) && (
                    <Typography color={"red"} fontSize={10}>
                      Masukan Tanggal
                    </Typography>
                  )}
                </Stack>
              )}
              {(activeFilter === "Expired" || activeFilter === "Sayur Box") && (
                <Stack>
                  <DatePicker
                    value={item.expired}
                    onChange={(newValue) => handleDate(item.id, newValue)}
                    format="DD/MM/YYYY"
                    label="Expired"
                  />

                  {dataLabel.some((item) => item.expired === null) && (
                    <Typography color={"red"} fontSize={10}>
                      Masukan Tanggal
                    </Typography>
                  )}
                </Stack>
              )}
            </Stack>

            <Button
              disabled={dataLabel.length == 1}
              sx={{
                height: 60,
              }}
              variant="outlined"
              color="error"
              onClick={() => handleDeleteLine(item.id)}
            >
              <HighlightOffIcon
                color={dataLabel.length == 1 ? "disabled" : "error"}
              />
            </Button>
          </Stack>
        ))}
      </Stack>
      {isOpen &&
        (activeFilter === "Alamat" ? (
          <ModalMom
            activeModal={activeModal}
            setactiveModal={setactiveModal}
            setdeletedeRow={setdeletedeRow}
            deletedeRow={deletedeRow}
            isOpen={isOpen}
            fetchDataMom={fetchDataMom}
            setisOpen={setisOpen}
            handleChangeText={handleChangeText}
            activeFilter={activeFilter}
            dataLabel={dataLabel}
            item={dataLabel.find((itemRow) => itemRow.id === activeModal.id)}
            SayurBoxProduct={SayurBoxProduct}
            handleProductChange={handleProductChange}
            masterMom={masterMom}
            handleMomChange={handleMomChange}
          />
        ) : (
          <ModalProduct
            handleChangeText={handleChangeText}
            handleMomChange={handleMomChange}
            activeModal={activeModal}
            setactiveModal={setactiveModal}
            setdeletedeRow={setdeletedeRow}
            deletedeRow={deletedeRow}
            isOpen={isOpen}
            setisOpen={setisOpen}
            activeFilter={activeFilter}
            dataLabel={dataLabel}
            item={dataLabel.find((itemRow) => itemRow.id === activeModal.id)}
            filterProduct={filterProduct}
            SayurBoxProduct={SayurBoxProduct}
            handleProductChange={handleProductChange}
          />
        ))}

      <Stack m={5} gap={5} direction={"row"}>
        {/* <Button
            color="success"
            onClick={handleProcess}
            disabled={
              errorMomDouble ||
              dataLabel.some((item) => item.qty < 1) ||
              dataLabel.some((item) => item.mom === null) ||
              dataLabel.some((item) => item.product === null) ||
              errorDouble
            }
            sx={{ maxWidth: 100 }}
            variant={"contained"}
          >
            Process
          </Button>
          {submit && ( */}
        <ReactToPrint
          trigger={() => (
            <Button
              onClick={() => setsubmit(false)}
              disabled={
                activeFilter === "Expired"
                  ? dataLabel.some((item) => item.expired === null) ||
                    dataLabel.some((item) => item.qty < 1)
                  : activeFilter === "Sayur Box"
                  ? dataLabel.some((item) => item.expired === null) ||
                    dataLabel.some((item) => item.production === null) ||
                    dataLabel.some((item) => item.qty < 1)
                  : activeFilter === "Alamat"
                  ? errorMomDouble || dataLabel.some((item) => item.qty < 1)
                  : errorDouble || dataLabel.some((item) => item.qty < 1)
                // activeFilter === "Expired"
                //   ? dataLabel.some((item) => item.expired === null)
                //   : false || activeFilter === "Sayur Box"
                //   ? dataLabel.some((item) => item.expired === null)
                //   : dataLabel.some((item) => item.production === null) ||
                //     activeFilter === "Sayur Buah"
                //   ? dataLabel.some((item) => item.expired === null)
                //   : false
              }
              sx={{ maxWidth: 100 }}
              variant={"contained"}
            >
              Print
            </Button>
          )}
          content={() => componentRef}
        />
        {/* )} */}
      </Stack>

      {activeFilter === "Expired" || activeFilter === "Sayur Buah" ? (
        filterExpired === "Produksi" ? (
          <div id="print_component">
            <div style={{ display: "flex" }}>
              <ComponentToPrint
                setisLoading={setisLoading}
                activeFilter={activeFilter}
                dataLabel={dataLabel}
                ref={(el) => (componentRef = el)}
              />
            </div>
          </div>
        ) : (
          <div id="print_component">
            <div style={{ display: "flex" }}>
              <ComponentToPrintIdm
                setisLoading={setisLoading}
                activeFilter={activeFilter}
                dataLabel={dataLabel}
                ref={(el) => (componentRef = el)}
              />
            </div>
          </div>
        )
      ) : activeFilter === "Alamat" ? (
        <div id="print_component">
          {/* component to be printed */}

          <div style={{ display: "flex" }}>
            <ComponentToPrintAlamat
              activeFilter={activeFilter}
              dataLabel={dataLabel}
              ref={(el) => (componentRef = el)}
            />
          </div>
        </div>
      ) : (
        <div id="print_component">
          {/* component to be printed */}

          <div style={{ display: "flex" }}>
            <ComponentToPrintSayurBox
              activeFilter={activeFilter}
              dataLabel={dataLabel}
              ref={(el) => (componentRef = el)}
            />
          </div>
        </div>
      )}
    </Card>
  );
};
class ComponentToPrintIdm extends React.Component {
  // componentDidMount() {
  //   // Set isLoading to false after the component is mounted
  //   this.props.setisLoading(false);
  // }
  render() {
    const { dataLabel, activeFilter, setisLoading } = this.props;
    const flattenedDataLabel = [];
    // Loop melalui setiap elemen dalam datalabel
    dataLabel.forEach((item) => {
      const { product, qty, expired, production } = item;
      // Ulang sebanyak kuantitas yang ditentukan oleh properti qty
      for (let i = 0; i < parseInt(qty); i++) {
        flattenedDataLabel.push({
          ...product,
          expired: dayjs(expired).format("DD/MM/YYYY"), // '25/01/2019',
          production: dayjs(production).format("DD/MM/YYYY"), // '25/01/2019',
        }); // Tambahkan produk ke array hasil
      }
      // setisLoading(false);
    });
    const chunkedDataLabel = [];
    // Bagi data menjadi kelompok berisi 6 item untuk setiap baris
    for (let i = 0; i < flattenedDataLabel.length; i += 6) {
      chunkedDataLabel.push(flattenedDataLabel.slice(i, i + 6));
    }
    return (
      <table border={0} style={{ border: 1 }}>
        <tbody>
          {chunkedDataLabel.map((chunk, rowIndex) => (
            <>
              <tr key={rowIndex} style={{ height: "11mm" }}>
                {chunk.map((label, cellIndex) => (
                  <React.Fragment key={cellIndex}>
                    {activeFilter === "Expired" ||
                    activeFilter === "Sayur Buah" ? (
                      <td
                        style={{
                          // backgroundColor: "yellow",
                          // textAlign: "center",
                          paddingLeft: "5mm",
                          fontWeight: "bold",
                          width: "30mm",
                          backgroundColor:
                            label?.expired.slice(0, 2) >= 1 &&
                            label?.expired.slice(0, 2) <= 7
                              ? "red"
                              : label?.expired.slice(0, 2) >= 8 &&
                                label?.expired.slice(0, 2) <= 15
                              ? "yellow"
                              : label?.expired.slice(0, 2) >= 16 &&
                                label?.expired.slice(0, 2) <= 23
                              ? "#4B70F5"
                              : "white",
                        }}
                      >
                        {activeFilter === "Expired" ? (
                          <Stack
                            justifyContent={"flex-end"}
                            color="black"
                            sx={{ width: "25mm" }}
                          >
                            <Typography fontWeight={"bold"} fontSize={10}>
                              MFG{" "}
                              {label.production
                                ?.toLocaleString()
                                .replace(/\//g, "-")}
                            </Typography>

                            <Typography fontWeight={"bold"} fontSize={10}>
                              EXP{" "}
                              {label.expired
                                ?.toLocaleString()
                                .replace(/\//g, "-")}
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack gap={"0.5mm"} alignItems={"center"}>
                            {/* <img
                                src={require("../../assets/images/bnw.png")}
                                style={{ width: "5mm" }}
                              /> */}
                            <Typography
                              sx={{ width: "25mm", color: "red" }}
                              fontSize={7}
                            >
                              {label.label.toUpperCase()}
                              {/* {cellIndex} */}
                            </Typography>
                          </Stack>
                        )}
                      </td>
                    ) : (
                      <td
                        style={
                          {
                            // textAlign: "center",
                            // width: "70mm",
                          }
                        }
                      >
                        <Stack
                          gap={1}
                          p={1}
                          border={1}
                          style={{
                            width: "38mm",
                            height: "18mm",
                          }}
                        >
                          <img
                            style={{
                              width: "20mm",
                              height: "5mm",
                            }}
                            src={require("../../assets/images/sayurbox.png")}
                          />
                          <Stack
                            justifyContent={"space-between"}
                            direction={"row"}
                          >
                            <Stack>
                              <Typography fontSize={5}>Produksi</Typography>
                              <Typography fontSize={5}>
                                PT. Golden Mom Indonesia
                              </Typography>

                              <Typography fontSize={5}>
                                {/* {JSON.stringify(label)} */}
                                {label.production?.toLocaleString()}
                              </Typography>
                              <Typography fontSize={6} fontWeight={"bold"}>
                                Expired : {label.expired?.toLocaleString()}
                              </Typography>
                            </Stack>
                            {/* <img
                              style={{
                                width: "10mm",
                                height: "10mm",
                              }}
                              src={require("../../assets/images/QRNegeri.png")}
                            /> */}
                          </Stack>
                        </Stack>
                      </td>
                    )}

                    {(cellIndex + 1) % 6 !== 0 &&
                      ((cellIndex + 1) % 2 === 0 ? (
                        // <td width={"16mm"}></td>
                        <td width={"10mm"}></td>
                      ) : (
                        <td width={"2mm"}></td>
                      ))}
                  </React.Fragment>
                ))}
              </tr>
              <tr style={{ height: "1mm" }} />
            </>
          ))}
        </tbody>
      </table>
    );
  }
}
class ComponentToPrint extends React.Component {
  // componentDidMount() {
  //   // Set isLoading to false after the component is mounted
  //   this.props.setisLoading(false);
  // }
  render() {
    const { dataLabel, activeFilter, setisLoading } = this.props;
    const flattenedDataLabel = [];
    // Loop melalui setiap elemen dalam datalabel
    dataLabel.forEach((item) => {
      const { product, qty, expired, production } = item;
      // Ulang sebanyak kuantitas yang ditentukan oleh properti qty
      for (let i = 0; i < parseInt(qty); i++) {
        flattenedDataLabel.push({
          ...product,
          expired: dayjs(expired).format("DD/MM/YYYY"), // '25/01/2019',
          production: dayjs(production).format("DD/MM/YYYY"), // '25/01/2019',
        }); // Tambahkan produk ke array hasil
      }
      // setisLoading(false);
    });
    const chunkedDataLabel = [];
    // Bagi data menjadi kelompok berisi 6 item untuk setiap baris
    for (let i = 0; i < flattenedDataLabel.length; i += 6) {
      chunkedDataLabel.push(flattenedDataLabel.slice(i, i + 6));
    }
    const replaceName = (string) => {
      return string.replace("TELUR - ", "");
    };
    return (
      <table border={0} style={{ border: 1 }}>
        <tbody>
          {chunkedDataLabel.map((chunk, rowIndex) => (
            <>
              <tr key={rowIndex} style={{ height: "11mm" }}>
                {chunk.map((label, cellIndex) => (
                  <React.Fragment key={cellIndex}>
                    {activeFilter === "Expired" ||
                    activeFilter === "Sayur Buah" ? (
                      <td
                        style={{
                          // backgroundColor: "yellow",
                          // textAlign: "center",
                          paddingLeft: "5mm",
                          fontWeight: "bold",
                          width: "30mm",
                        }}
                      >
                        {activeFilter === "Expired" ? (
                          <Stack
                            bg="yellow"
                            justifyContent={"flex-end"}
                            color="red"
                            sx={{ width: "25mm" }}
                          >
                            <Typography fontWeight={"bold"} fontSize={6}>
                              KODE PRODUKSI
                            </Typography>
                            <Typography fontWeight={"bold"} fontSize={6}>
                              {replaceName(label.label).toUpperCase()}
                            </Typography>

                            <Typography fontWeight={"bold"} fontSize={6}>
                              EXP {label.expired?.toLocaleString()}
                            </Typography>
                          </Stack>
                        ) : (
                          <Stack gap={"0.5mm"} alignItems={"center"}>
                            {/* <img
                                src={require("../../assets/images/bnw.png")}
                                style={{ width: "5mm" }}
                              /> */}
                            <Typography
                              sx={{ width: "25mm", color: "red" }}
                              fontSize={7}
                            >
                              {label.label.toUpperCase()}
                              {/* {cellIndex} */}
                            </Typography>
                          </Stack>
                        )}
                      </td>
                    ) : (
                      <td
                        style={
                          {
                            // textAlign: "center",
                            // width: "70mm",
                          }
                        }
                      >
                        <Stack
                          gap={1}
                          p={1}
                          border={1}
                          style={{
                            width: "38mm",
                            height: "18mm",
                          }}
                        >
                          <img
                            style={{
                              width: "20mm",
                              height: "5mm",
                            }}
                            src={require("../../assets/images/sayurbox.png")}
                          />
                          <Stack
                            justifyContent={"space-between"}
                            direction={"row"}
                          >
                            <Stack>
                              <Typography fontSize={5}>Produksi</Typography>
                              <Typography fontSize={5}>
                                PT. Golden Mom Indonesia
                              </Typography>

                              <Typography fontSize={5}>
                                {/* {JSON.stringify(label)} */}
                                {label.production?.toLocaleString()}
                              </Typography>
                              <Typography fontSize={6} fontWeight={"bold"}>
                                Expired : {label.expired?.toLocaleString()}
                              </Typography>
                            </Stack>
                            {/* <img
                              style={{
                                width: "10mm",
                                height: "10mm",
                              }}
                              src={require("../../assets/images/QRNegeri.png")}
                            /> */}
                          </Stack>
                        </Stack>
                      </td>
                    )}

                    {(cellIndex + 1) % 6 !== 0 &&
                      ((cellIndex + 1) % 2 === 0 ? (
                        // <td width={"16mm"}></td>
                        <td width={"10mm"}></td>
                      ) : (
                        <td width={"2mm"}></td>
                      ))}
                  </React.Fragment>
                ))}
              </tr>
              <tr style={{ height: "1mm" }} />
            </>
          ))}
        </tbody>
      </table>
    );
  }
}
class ComponentToPrintSayurBox extends React.Component {
  render() {
    const { dataLabel, activeFilter } = this.props;

    const flattenedDataLabel = [];
    // Loop melalui setiap elemen dalam datalabel
    dataLabel.forEach((item) => {
      const { product, qty, expired, production } = item;
      // Ulang sebanyak kuantitas yang ditentukan oleh properti qty
      for (let i = 0; i < parseInt(qty); i++) {
        flattenedDataLabel.push({
          ...product,
          expired: dayjs(expired).format("DD/MM/YYYY"), // '25/01/2019',
          production: dayjs(production).format("DD/MM/YYYY"), // '25/01/2019',
        }); // Tambahkan produk ke array hasil
      }
    });

    const chunkedDataLabel = [];
    // Bagi data menjadi kelompok berisi 6 item untuk setiap baris
    for (let i = 0; i < flattenedDataLabel.length; i += 5) {
      chunkedDataLabel.push(flattenedDataLabel.slice(i, i + 5));
    }

    const replaceName = (string) => {
      return string.replace("TELUR - ", "");
    };

    return (
      <table border={0} style={{ border: 0 }}>
        <tbody>
          {chunkedDataLabel.map((chunk, rowIndex) => (
            <>
              <tr key={rowIndex} style={{ height: "11mm" }}>
                {chunk.map((label, cellIndex) =>
                  label.label.includes("OMEGA") ||
                  label.label.includes("NEGERI") ? (
                    <React.Fragment key={cellIndex}>
                      <td
                        style={{
                          // backgroundColor: "yellow",
                          paddingLeft: 5,
                          alignItems: "center",
                          // textAlign: "center",
                          // width: "70mm",
                        }}
                      >
                        <Stack
                          pl={5}
                          direction={"row"}
                          // ml={2}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={1}
                          p={0.5}
                          border={0}
                          style={{
                            width: "35mm",
                            height: "14mm",
                          }}
                        >
                          <Stack
                            alignItems={"center"}
                            // justifyContent={"space-between"}
                            gap={0}
                          >
                            <img
                              style={{
                                marginLeft: "0.2mm",
                                marginTop: "0.1mm",
                                width: "20mm",
                                height: "3mm",
                              }}
                              src={require("../../assets/images/sayurbox.png")}
                            />
                            <Stack>
                              <Typography textAlign={"center"} fontSize={5}>
                                Produksi
                              </Typography>
                              <Typography textAlign={"center"} fontSize={5}>
                                PT. Golden Mom Indonesia
                              </Typography>
                              <Typography textAlign={"center"} fontSize={5}>
                                {label.label}
                              </Typography>

                              <Typography textAlign={"center"} fontSize={5}>
                                {/* {JSON.stringify(label)} */}
                                {label.production?.toLocaleString()}
                              </Typography>
                              <Typography
                                textAlign={"center"}
                                fontSize={5}
                                fontWeight={"bold"}
                              >
                                Exp. {label.expired?.toLocaleString()}
                              </Typography>
                            </Stack>
                          </Stack>
                          <img
                            style={{
                              width: "8mm",
                              height: "8mm",
                              resizeMode: "cover",
                            }}
                            src={require(`../../assets/images/${label.label}.png`)}
                          />
                        </Stack>
                      </td>
                      <td width={"6mm"} />
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={cellIndex}>
                      <td
                        style={{
                          // backgroundColor: "yellow",
                          paddingLeft: 5,
                          alignItems: "center",
                          // textAlign: "center",
                          // width: "70mm",
                        }}
                      >
                        <Stack
                          pl={5}
                          direction={"row"}
                          // ml={2}
                          alignItems={"center"}
                          justifyContent={"center"}
                          gap={1}
                          p={0.5}
                          border={0}
                          style={{
                            width: "35mm",
                            height: "14mm",
                          }}
                        >
                          <Stack
                            alignItems={"center"}
                            // justifyContent={"space-between"}
                            gap={0}
                          >
                            <Stack>
                              <Typography textAlign={"center"} fontSize={7}>
                                {label.label}
                              </Typography>
                            </Stack>
                          </Stack>
                          <img
                            style={{
                              width: "8mm",
                              height: "8mm",
                              resizeMode: "cover",
                            }}
                            src={require(`../../assets/images/${label.label}.png`)}
                          />
                        </Stack>
                      </td>
                      <td width={"6mm"} />
                    </React.Fragment>
                  )
                )}
              </tr>
              <tr style={{ height: "1.9mm" }} />
            </>
          ))}
        </tbody>
      </table>
    );
  }
}
class ComponentToPrintAlamat extends React.Component {
  render() {
    const { dataLabel, activeFilter } = this.props;

    const flattenedDataLabel = [];
    // Loop melalui setiap elemen dalam datalabel
    dataLabel.forEach((item) => {
      const { product, qty, expired, production, mom } = item;
      // Ulang sebanyak kuantitas yang ditentukan oleh properti qty
      for (let i = 0; i < parseInt(qty); i++) {
        flattenedDataLabel.push({
          ...product,
          mom,
          expired: dayjs(expired).format("DD/MM/YYYY"), // '25/01/2019',
          production: dayjs(production).format("DD/MM/YYYY"), // '25/01/2019',
        }); // Tambahkan produk ke array hasil
      }
    });

    const chunkedDataLabel = [];
    // Bagi data menjadi kelompok berisi 6 item untuk setiap baris
    for (let i = 0; i < flattenedDataLabel.length; i += 3) {
      chunkedDataLabel.push(flattenedDataLabel.slice(i, i + 3));
    }

    const replaceName = (string) => {
      return string.replace("TELUR - ", "");
    };
    console.log(chunkedDataLabel);
    return (
      <table border={0} style={{ border: 1 }}>
        <tbody>
          {chunkedDataLabel.map((chunk, rowIndex) => (
            <>
              <tr key={rowIndex} style={{ height: "11mm" }}>
                {chunk.map((label, cellIndex) => (
                  <React.Fragment key={cellIndex}>
                    <td
                      style={
                        {
                          // color: "red",
                          // textAlign: "center",
                          // width: "70mm",
                        }
                      }
                    >
                      <Stack
                        justifyContent={"center"}
                        gap={1}
                        p={2}
                        border={0}
                        style={{
                          textAlign: "center",
                          width: "64mm",
                          height: "30mm",
                        }}
                      >
                        <Typography fontSize={12} fontWeight={"bold"}>
                          {label.mom.label}
                        </Typography>
                        <Typography
                          fontSize={10}
                          style={{
                            textDecoration: "underline",
                          }}
                        >
                          {label.mom.areaKirim}
                        </Typography>

                        <Typography fontSize={8}>
                          {/* {JSON.stringify(label)} */}
                          {label.mom.address}
                        </Typography>
                      </Stack>
                    </td>

                    {/* {(cellIndex + 1) % 4 !== 0 &&
                      ((cellIndex + 1) % 2 === 0 ? (
                        // <td width={"16mm"}></td>
                        <td width={"10mm"}></td>
                      ) : (
                      ))} */}
                    <td width={"8mm"}></td>
                  </React.Fragment>
                ))}
              </tr>
              <tr style={{ height: "3.5mm" }} />
            </>
          ))}
        </tbody>
      </table>
    );
  }
}
const Sample = () => {
  const ww = "210";
  const wh = "148";
  return (
    <div>
      <div>
        <table style={{ position: "absolute" }}>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => {
            return (
              <tr key={index}>
                <td
                  style={{
                    paddingRight: ww * 0.05,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>

                <td
                  style={{
                    paddingRight: ww * 0.03,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>
                <td width={ww * 0.1} />
                <td
                  style={{
                    paddingRight: ww * 0.1,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>

                <td
                  style={{
                    paddingRight: ww * 0.03,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>
                <td width={ww * 0.1} />
                <td
                  style={{
                    paddingRight: ww * 0.03,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>

                <td
                  style={{
                    paddingRight: ww * 0.03,
                    paddingLeft: ww * 0.04,
                  }}
                >
                  <Typography fontSize={ww * 0.048}>Kode Produksi</Typography>
                  <Typography fontSize={ww * 0.048}>
                    Telur Asin Merah Udang
                  </Typography>
                  <Typography fontSize={ww * 0.048}>
                    Expired : 22/08/2024
                  </Typography>
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

export default LabelFormat;
